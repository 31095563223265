<template>
    <div>
        <div class="toolBar mBottom10">
            <el-cascader v-show="global.isAdmin" :options="global.orgList" :show-all-levels="false" v-model="global.orgId"
                         :props="global.orgTreeProps" @change='orgChange' size="small"></el-cascader>
            <el-button type="primary" size='small' plain @click="addDepUser" style="margin-left: 20px">新增</el-button>
            <el-button plain size='small' class='fr' @click="refresh">刷新</el-button>
        </div>

        <el-row :gutter='10'>
            <el-col :md='6'>
                <el-card class="box-card orgItem">
                    <div slot="header">
                        <div class="institutional" v-show="!this.deparmentName">部门结构</div>
                        <div class="institutional">{{deparmentName}}</div>
                        <el-button class="fr" type="text" size="mini" v-show="this.deparmentName" @click="clear">重置
                        </el-button>
                    </div>
                    <div class="leftTree">
                        <el-tree :data="data" node-key="depId" default-expand-all :expand-on-click-node="false"
                                 highlight-current
                                 check-strictly
                                 ref="treeBox"
                                 :check-strictly=true
                                 @node-click="handleNodeClick"
                                 :props="defaultProps">
                            <div class="custom-tree-node"
                                 style=' flex: 1;display: flex;align-items: center;justify-content: space-between;'
                                 slot-scope="{ node, data }">
                                <span>{{ node.label }}</span>
                            </div>
                        </el-tree>
                    </div>
                    <div>
                        <el-button size="medium" plain type="primary" style="width: 99%;" class="maintain"
                                   @click="maintainDep">维护部门
                        </el-button>
                    </div>
                </el-card>
            </el-col>

            <el-col :md='18'>
                <el-card class="box-card orgItem2">
                    <el-table :data="userData" size='small' style="width: 100%" :height='tableHeight'>
                        <el-table-column type='index' label="序号" align='center'></el-table-column>
                        <el-table-column prop="depName" label="部门" align='center'></el-table-column>
                        <el-table-column prop="userName" label="姓名" align='center'></el-table-column>
                        <el-table-column prop="phone" label="手机号" align='center'></el-table-column>
                        <el-table-column prop="roleName" label="角色名称" align='center'></el-table-column>
                        <el-table-column label="操作" align='center'>
                            <template slot-scope="scope">
                                <el-button @click="handleEdit(scope.$index,scope.row)" style='padding: 0;' type="text">
                                    编辑
                                </el-button>
                                <el-button @click="handleEditPwd(scope.$index,scope.row)" style='padding: 0;'
                                           type="text">重置密码
                                </el-button>
                                <el-button @click="handleDelete(scope.$index,scope.row)" style='padding: 0;color: red'
                                           type="text">删除
                                </el-button>
                                <el-button @click="handleAllocation(scope.$index,scope.row)" style='padding: 0;'
                                           type="text">分配角色
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>

                <div class="toolBar clearfix" style="margin-top: 10px">
                    <pagi-nation :page='orgUserPage' @sizeChange='orgUserSizeChange'
                                 @currentChange='orgUserCurrentChange'></pagi-nation>
                </div>
            </el-col>
        </el-row>

        <el-dialog v-dialogDrag :visible.sync="userFromPCDialog" width="40%"  title="编辑">
            <el-form label-width="120px" ref='getUserForm' :rules="editFormRules" :model='getUserForm' size='small'>
                <el-row>
                    <el-col :sm="24">
                        <el-form-item label="所属部门：">
                            <el-cascader v-model="getUserForm.depIds" :props="selectProps" :options="depOptions"
                                         filterable clearable style="width: 100%"></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="用户姓名：">
                            <el-input v-model="getUserForm.name" clearable placeholder="姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="身份证号：">
                            <el-input :maxlength=18 v-model="getUserForm.idNumber" clearable
                                      placeholder="身份证号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="密码：" prop="pwd">
                            <el-input :maxlength=18 v-model="getUserForm.pwd" clearable
                                      placeholder="字母+数字，6-32位"></el-input>
                        </el-form-item>
                    </el-col>
                    <!--<el-col :sm="24">
                        <el-form-item label="头像">
                            <div class="preImg">
                                <label class="btn" for="uploadB">
                                    <img v-if="getUserForm.headUrl" :src="getUserForm.headUrl" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </label>
                                <input type="file" ref='uploadInputB' id="uploadB" style="position:absolute; clip:rect(0 0 0 0);" accept="image/*" @change="uploadImgB($event)" :disabled="edit">
                                <upload-img ref='uploadImgB' :autoCropWidth='375' :autoCropHeight='188' :uploadDlgVisible='uploadDlgVisibleB'
                                            @hide='hideB' @handleSuccessImg='handleSuccessImgB'></upload-img>
                            </div>
                            <div style="color: #e6a23c;">图片比例为375*188</div>
                            &lt;!&ndash;								<el-button type="text" @click="deleteImage" v-show="this.type==1 && editActivityForm.previewImage">移除图片</el-button>&ndash;&gt;
                        </el-form-item>
                    </el-col>-->
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="userFromPCDialog = false" size='small'>取消</el-button>
                <el-button @click="handleEditForm" type="primary" size='mini'>确定</el-button>
            </div>
        </el-dialog>

        <el-dialog v-dialogDrag :visible.sync="orgDepUserDialog" width="40%" title="新增">
            <el-form label-width="100px" ref='depUserForm' :rules="depUserFormRules" :model='depUserForm' size='small'>
                <el-row>
                    <el-col :sm="24">
                        <el-form-item label="所属部门：">
                            <el-cascader v-model="depUserForm.depIds" :props="selectProps" :options="depOptions"
                                         filterable clearable style="width: 100%"></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="姓名：" prop="name">
                            <el-input v-model="depUserForm.name" clearable placeholder="请输入姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="手机号码：" prop="phone">
                            <el-input :maxlength=11 v-model="depUserForm.phone" clearable
                                      placeholder="11位手机号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="登录密码：" prop="pwd">
                            <el-input v-model="depUserForm.pwd" clearable placeholder="字母+数字，6-32位"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="身份证号：">
                            <el-input v-model="depUserForm.idNumber" clearable placeholder="请输入身份证号"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="orgDepUserDialog = false" size='small'>取消</el-button>
                <el-button @click="success" type="primary" size='mini'>确定</el-button>
            </div>
        </el-dialog>

        <el-dialog v-dialogDrag :visible.sync="userFromDialog" width="40%" title="重置密码">
            <el-form label-width="100px" ref='editUserForm' :model='editUserForm' size='small'
                     :rules="editUserFormRules">
                <el-row>
                    <el-col :sm="24">
                        <el-form-item label="用户姓名：">
                            <el-input v-model="editUserForm.userName" clearable placeholder="姓名"
                                      :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :sm="24">
                        <el-form-item label="密码：" prop="pwd">
                            <el-input :maxlength=18 v-model="editUserForm.pwd" clearable placeholder="密码"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="userFromDialog = false" size='small'>取消</el-button>
                <el-button @click="handle" type="primary" size='mini'>确定</el-button>
            </div>
        </el-dialog>

        <el-dialog v-dialogDrag :visible.sync="assignRolesDialog" width="40%">
            <el-table :data="roleList" ref="multipleTable" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column type='index' label="序号"></el-table-column>
                <el-table-column property="name" label="角色名称" align='center'></el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="assignRolesDialog = false" size='small'>取消</el-button>
                <el-button @click="submit" type="primary" size='small'>保存</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import uploadImg from '@/components/uploadImg/uploadImg'

    var validPhone = (rule, value, callback) => {
        if (!value) {
            callback(new Error('请输入电话号码'))
        } else if (!isvalidPhone(value)) {
            callback(new Error('请输入正确的11位手机号码'))
        } else {
            callback()
        }
    }

    class UserForm {
        constructor() {
            this.orgId = null;
            this.depIds = [];
            this.depId = null;
            this.name = null;
            this.phone = null;
            this.pwd = null;
            this.idNumber = null;
            this.isConfirm = null;
        }

        reset() {
            this.constructor();
        }
    }

    class UserInfoForm {
        constructor() {
            this.userId = null;
            this.name = null;
            this.idNumber = null;
            this.preview = null;
            this.headUrl = null;
            this.pwd = null;
        }

        reset() {
            this.constructor();
        }
    }

    class UserPage {
        constructor() {
            this.total = 0;
            this.pageSizes = [50, 100, 150, 200, 300, 400, 500];
            this.pageSize = this.pageSizes[0];
            this.pagerCount = 5;
            this.currentPage = 1;
        }

        reset() {
            this.constructor();
        }
    }

    export default {
        components: {uploadImg},
        data() {
            return {
                deparmentName: null,
                edit: false,
                clickObj: {},
                isLoading: false,
                assignRolesDialog: false,
                userFromPCDialog: false,
                uploadDlgVisibleB: false,
                userFromDialog: false,
                id: null,
                deparmentId: null,
                deparmentIds: [],
                defaultProps: {children: 'children', label: 'depName', id: "depId"},
                selectProps: {children: 'children', label: 'depName', value: "depId", checkStrictly: true},
                depOptions: [],
                depUserForm: new UserForm(),
                editUserForm: new UserInfoForm(),
                getUserForm: new UserInfoForm(),
                orgDepUserDialog: false,
                orgUserPage: new UserPage(),
                tableHeight: document.documentElement.clientHeight - 288,
                userData: [],
                roleList: [],
                value: null,
                deps: null,
                data: [],
                form: {},
                depUserFormRules: {
                    name: [{required: true, message: '姓名是必须的', trigger: 'blur'}],
                    phone: [{required: true, trigger: 'blur', validator: validPhone}],
                    idNumber: [{required: true, message: '请填写证件号码', trigger: 'blur'},
                        {
                            pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
                            message: '证件号码格式有误！', trigger: 'blur'
                        }],
                    pwd: [
                        {min: 6, max: 32, message: '长度在 6 到 32 个字符'},
                        {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,32}$/, message: '字母+数字,长度6-32个字符'}],
                    depIds: [{required: true, message: '部门是必须的', trigger: 'blur'}],
                },
                editUserFormRules: {
                    pwd: [{required: true, message: '密码是必须的', trigger: 'blur'},
                        {min: 6, max: 32, message: '长度在 6 到 32 个字符'},
                        {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,32}$/, message: '字母+数字,长度6-32个字符'}],
                },
                editFormRules: {
                    pwd: [
                        {min: 6, max: 32, message: '长度在 6 到 32 个字符'},
                        {pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,32}$/, message: '字母+数字,长度6-32个字符'}],
                },
                multipleSelection: [],
            }
        },
        computed: {
        },
        methods: {
            resetForm(formName) {
                if (this.$refs[formName] !== undefined) {
                    this.$refs[formName].resetFields();
                }
            },
            uploadImgB(e) {
                this.uploadDlgVisibleB = true;
                this.$refs['uploadImgB'].uploadImg(e);
            },
            hideB() {
                this.$refs['uploadInputB'].value = null;
                this.uploadDlgVisibleB = false;
            },
            handleSuccessImgB(data) {
                this.$message.success('上传成功！');
                this.getUserForm.headUrl = IMAGE_BASE + data;
                this.getUserForm.preview = data;
                this.hideB();
            },
            handleNodeClick(data) {
                this.clickObj = data;
                this.deparmentName = data.depName;
                this.deparmentId = data.depId;
                this.getDepUserList(data.depId)
            },
            orgChange(val) {
                this.deparmentId = null;
                if (val && val.length) {
                    localStorage.setItem('orgId',JSON.stringify(val));
                    this.refresh();
                }
            },
            refresh() {
                this.clear();
            },
            clear() {
                this.deparmentName = null;
                this.load();
                this.getDepUserList();
            },
            addDepUser() {
                this.depUserForm.reset();
                this.resetForm('depUserForm');
                this.orgDepUserDialog = true;
            },
            handleEdit(index, row) {
                if (row.depId) {
                    this.getUserForm.depIds = findIndexArray(this.depOptions, row.depId, []);
                }
                if (row.userName) {
                    this.getUserForm.name = row.userName;
                }
                Object.assign(this.getUserForm, row);
                this.userFromPCDialog = true;
            },
            handleEditPwd(index, row) {
                this.resetForm('editUserForm');
                this.editUserForm.reset();
                Object.assign(this.editUserForm, row);
                this.userFromDialog = true;
            },
            handleEditForm() {
                this.$refs['getUserForm'].validate((valid) => {
                    if (valid) {//表单验证通过
                        this.commit();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            handle() {
                this.$refs['editUserForm'].validate((valid) => {
                    if (valid) {//表单验证通过
                        this.handleSuccess();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            async handleSuccess() {
                let param = {
                    userId: this.editUserForm.userId,
                    pwd: this.editUserForm.pwd,
                };
                this.userFromDialog = false;
                let res = await this.$http.post('/sys/resetPassword', param);
                if (res.state) {
                    this.$message({type: 'success', message: '重置成功'});
                }
                if (this.deparmentId) {
                    this.$refs.treeBox.setCurrentKey(this.deparmentId);
                    this.getDepUserList(this.deparmentId)
                } else {
                    this.getDepUserList();
                }
            },
            async getUserInfo(id) {
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let param = {
                    id: id,
                    orgId: orgId
                };
                let res = await this.$http.post('/sys/getOrgDepUser', param);
                if (res.state) {
                    this.getUserForm = res.data;
                    this.deparmentId = res.data.depId;
                    this.getUserForm.depIds = findIndexArray(this.depOptions, res.data.depId, []);
                }
            },
            async commit() {
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let param = {
                    orgId: orgId,
                    depId: null,
                    userId: this.getUserForm.userId,
                    userName: this.getUserForm.name,
                    idNumber: this.getUserForm.idNumber,
                    pwd: this.getUserForm.pwd
                };
                if (this.getUserForm.depIds) {
                    let depArr = this.getUserForm.depIds.length
                    param.depId = this.getUserForm.depIds[depArr - 1]
                }
                if (param.idNumber) {
                    let reg = /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/;
                    if (!reg.test(param.idNumber)) {
                        this.$message.warning('请输入合法身份证！');
                        return false;
                    }
                }
                if (param.pwd) {
                    let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,32}$/;
                    if (!reg.test(param.pwd)) {
                        this.$message.warning('密码格式错误！');
                        return false;
                    }
                }
                let res = await this.$http.post('/sys/updOrgUser', param);
                if (res.state) {
                    this.$message.success('修改成功！');
                    this.userFromPCDialog = false;
                    if (this.deparmentId) {
                        this.$refs.treeBox.setCurrentKey(this.deparmentId);
                        this.getDepUserList(this.deparmentId)
                    } else {
                        this.getDepUserList()
                    }
                } else {
                    console.log(res.msg)
                }
            },
            handleDelete(index, row) {
                this.id = row.userId;
                this.$confirm('是否删除该用户, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.deleteOrgUser();
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            async deleteOrgUser() {
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let param = {
                    orgId: orgId,
                    userId: this.id
                };
                let res = await this.$http.post('/sys/delOrgUser', param);
                if (res.state) {
                    this.$message({type: 'success', message: '删除成功!'});
                } else {
                    return false
                }
                if (this.deparmentId) {
                    this.$refs.treeBox.setCurrentKey(this.deparmentId);
                    this.getDepUserList(this.deparmentId)
                } else {
                    this.getDepUserList();
                }
            },
            async addOrgDepUser() {
                let orgId = this.global.orgId[this.global.orgId.length-1];
                this.depUserForm.orgId = orgId;
                if (this.depUserForm.depIds) {
                    let depIdArr = this.depUserForm.depIds.length;
                    this.depUserForm.depId = this.depUserForm.depIds[depIdArr - 1]
                }
                let res = await this.$http.post('/sys/addOrgUser', this.depUserForm);
                if (res) {
                    if (res.state) {
                        this.$message({message: '新增成功', type: 'success'});
                        this.orgDepUserDialog = false;
                        this.getDepUserList();
                    } else {
                        console.log(res.msg)
                    }
                    if (res.code == "user_007") {
                        const h = this.$createElement;
                        let name='姓名：' + (res.data.userName ? res.data.userName : '暂无') + '';
                        let phone='手机号：' + (res.data.phone ? res.data.phone: '暂无') + '';
                        this.$msgbox({
                            title: '提示',
                            customClass: 'message-logout',
                            message: h('p', null, [
                                h('div', {}, '手机号为' + res.data.phone + '的用户已存在，是否将其添加到当前机构下？'),
                                h('div', {}, name),
                                h('div', {}, phone),
                                h('span', {}, '身份证号：'),
                                h('span', {}, res.data.idNumber ? res.data.idNumber : '暂无'),
                            ]),
                            showCancelButton: true,
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            this.confirmAddUser(res.data.userId);
                            this.orgDepUserDialog = false;
                        });
                    }
                    if (res.code == "org_user_001") {
                        const h = this.$createElement;
                        this.$msgbox({
                            title: '提示',
                            customClass: 'message-logout',
                            message: h('p', null, [
                                h('div', {}, '手机号为' + res.data.phone + '的用户，已经存在当前机构下！'),
                                h('div', {}, '姓名：' + res.data.userName ? res.data.userName : '暂无' + ''),
                                h('div', {}, '手机号：' + res.data.phone  ? res.data.phone: '暂无' + ''),
                                h('span', {}, '身份证号：'),
                                h('span', {}, res.data.idNumber ? res.data.idNumber : '暂无'),
                            ]),
                            showCancelButton: true,
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })
                    }
                }
            },
            async confirmAddUser(id) {
                let param = {
                    depId: this.depUserForm.depId,
                    orgId: this.depUserForm.orgId,
                    userId: id
                };
                let res = await this.$http.post('/sys/confirmAddOrgUser', param);
                if (res.state) {
                    this.$message({message: '成功', type: 'success'});
                    this.getDepUserList();
                }
            },
            success() {
                this.$refs['depUserForm'].validate((valid) => {
                    if (valid) {//表单验证通过
                        this.addOrgDepUser();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            handleAllocation(index, row) {
                this.assignRolesDialog = true;
                this.id = row.userId;
                this.getDepUserRoleList(row.userId)
            },
            changeSelectRole(res) {
                for (let i of res.data) {
                    if (i.isTrue == true) {
                        this.$nextTick(() => this.$refs.multipleTable.toggleRowSelection(i, true));
                    }
                }
            },
            initSelectRole() {
                this.$refs.multipleTable.clearSelection(false);
            },
            async submit() {
                let roleIds = this.multipleSelection.map(item => item.id);
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let param = {
                    orgId: orgId,
                    roleIds: roleIds,
                    userId: this.id
                };
                let res = await this.$http.post('/sys/updOrgUserRole', param);
                this.assignRolesDialog = false;
                if (res.state) {
                    this.$message({message: '分配成功', type: 'success'});
                }
                this.getDepUserList(this.deparmentId);
            },
            async getDepUserRoleList(val) {
                this.roleList.length && this.initSelectRole();
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let param = {
                    orgId: orgId,
                    userId: val,
                };
                let res = await this.$http.post('/sys/userRoleList', param);
                if (res.data) {
                    this.roleList = res.data;
                    this.changeSelectRole(res)
                    // setTimeout(() => {
                    //     this.changeSelectRole(res)
                    // }, 0)
                } else {
                    return false
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            orgUserSizeChange(val) {
                this.orgUserPage.currentPage = 1;
                this.orgUserPage.pageSize = val;
                this.getDepUserList();
            },
            orgUserCurrentChange(val) {
                this.orgUserPage.currentPage = val;
                this.getDepUserList();
            },
            async getDepUserList(val) {
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let param = {
                    depId: val,
                    orgId: orgId,
                    limit: this.orgUserPage.pageSize,
                    offSet: (this.orgUserPage.currentPage - 1) * this.orgUserPage.pageSize,
                };
                this.isLoading = true;
                let res = await this.$http.post('/sys/getDepUser', param);
                if (res.data) {
                    this.userData = res.data.list;
                    this.orgUserPage.total = res.data.totalCount;
                }
                this.isLoading = false;
            },
            async load() {
                let orgId = this.global.orgId[this.global.orgId.length-1];
                let param = {orgId: orgId};
                let res = await this.$http.post('/sys/orgDepTree', param);
                if (res.state) {
                    if (res.data) {
                        this.data = res.data;
                        this.depOptions = res.data;
                    } else {
                        this.data = [];
                        this.depOptions = [];
                        this.userData = []
                    }
                } else {
                    return false
                }
            },
            maintainDep() {
                this.$router.push('orgDep');
            },
            init() {
                if (this.global.orgId.length) {
                    this.load();
                    this.getDepUserList();
                }else {
                    setTimeout(() => {
                        this.init()
                    }, 200)
                }
            }
        },
        created() {
            this.init()
        },
        mounted() {
            window.onresize = () => {
                window.fullHeight = document.documentElement.clientHeight
                this.tableHeight = window.fullHeight - 120;
            }
        }
    }

    function isvalidPhone(str) {
        const reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/
        return reg.test(str)
    }

    function findIndexArray(data, id, indexArray) {
        let arr = Array.from(indexArray);
        for (let item of data) {
            arr.push(item.depId);
            if (item.depId === id) {
                return arr;
            }
            let children = item.children;
            if (children && children.length) {
                let result = findIndexArray(children, id, arr);
                if (result) {
                    return result
                }
            }
            arr.pop()
        }
        return false
    }
</script>

<style scoped>
    .orgItem {
        /*margin: 5px;*/
        height: calc(100vh - 188px);
    }

    orgItem2 {
        /*margin: 5px;*/
    }

    .leftTree {
        height: calc(100vh - 350px);
    }

    .institutional {
        display: inline-block;
    }

    .preImg {
        display: flex;
        align-items: center;
    }

    .avatar-uploader-icon {
        display: inline-block;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }

    .avatar-uploader-icon:hover {
        border-color: #aaa;
    }

    .avatar {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        max-width: 120px;
        display: inline-block;
        vertical-align: middle;
    }
</style>
<style>
    .el-message-box__status {
        position: absolute;
        top: 18%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 24px !important;
    }

    .message-logout {
        width: 25%;
    }
</style>